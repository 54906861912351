import React from "react"
import * as style from "./Header.module.scss"
import { GatsbyImage } from "gatsby-plugin-image"
import { getBackgroundImage, getGatsbyImage } from "../../plugins/common"
import { CustomMarkdown } from "../../plugins/sanitizehtml"
import Button from "../Button"

const Header = ({ data, lang }) => {
  const { title, subtitle, image, cta, backgroundImage } = data
  const bg = getBackgroundImage(backgroundImage)
  return (
    <div
      className={`${style.header} position-relative d-lg-grid d-flex flex-column`}
      style={{ backgroundImage: `url(${bg})` }}
    >
      <div className={`${style.content} align-self-center mx-lg-0 mx-5`}>
        <h1 className={`ming text-lg-start text-center`}>
          <CustomMarkdown html={title} color={`copper`} />
        </h1>
        <div
          className={`ming openSans text-lg-start text-center ${style.desc}`}
        >
          <CustomMarkdown html={subtitle} color={`copper`} />
        </div>
        {cta && (
          <div className={`mt-4 text-lg-start text-center`}>
            <Button
              buttonId={`hero_quote_cta`}
              lang={lang}
              type={cta.template}
              text={cta.buttonLabel}
              url={cta.url}
            />
          </div>
        )}
      </div>
      {image && (
        <div className={`${style.imageWrapper} position-relative`}>
          <GatsbyImage
            sizes="(max-width: 370px) 37w, (max-width: 500px) 50vw,(max-width: 800px) 80vw,  100vw"
            className={`${style.image} imageAni position-absolute`}
            image={getGatsbyImage(image)}
            alt="alea"
          />
        </div>
      )}
    </div>
  )
}

export default Header
